import app from "#app/index.js";
import $ from "cash-dom";
import Particles from "#app/particles";
// import {initFetch, default as fetchInterceptors} from "./fetch-interceptors";
// import "intersection-observer";
import {lineLimit, line} from "./animation";
import blurred from "#app/common/blurred";
import isMobile from "ismobilejs";
import {captchaX} from "#app/captcha";
import Cookie from "cookie-universal";
import {arrayBufferToBase64, stringToArrayBuffer} from "./binary-utils";

const cookies = Cookie();

function findAllFonts (el = document.documentElement, result = {}) {
	const style = window.getComputedStyle(el);
	result[style.fontFamily] = result[style.fontFamily] || {};
	result[style.fontFamily][style.fontWeight] = result[style.fontFamily][style.fontWeight] || [];
	result[style.fontFamily][style.fontWeight].push(el);
	[...el.children].forEach(el => {
		findAllFonts(el, result);
	});
	return result;
}

const quant = {
	"uk": ["", "тис.", "млн", "млрд", "трлн"],
	"ru": ["", "тыс.", "млн", "млрд", "трлн"],
	"en": ["", "k", "mln", "bln", "tln"],
};

const isSafari = /apple/i.test(navigator.vendor);

const tryEx = (tryFn, catchFn) => {
	try {
		return tryFn();
	}
	catch (error) {
		if (typeof catchFn === "function") {
			return catchFn(error);
		}
		return catchFn;
	}
};

let modalId = 0;

const trackingParamsKeys = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content", "utm_referrer", "gclid", "fbclid", "_fbc", "_fbp", "site_clid"];

const common = {
	$data: new WeakMap(),
	isSafari,
	encodeXLinkParams (params) {
		function escapeBase64 (string) {
			return string.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+/g, "");
		}

		const linkParamsShorcutMap = {
			link_source: "ls",
			link_type: "lt",
			utm_source: "src",
			utm_medium: "m",
			utm_campaign: "cmp",
			utm_content: "cnt",
			utm_term: "t",
			utm_referrer: "r",
			fbclid: "fb",
			gclid: "g",
			"_fbc": "fbc",
			"_fbp": "fbp",
			site_clid: "cl",
		};
		const linkParams = {};

		Object.keys(params).forEach(key => {
			const shortKey = linkParamsShorcutMap[key] || key;
			linkParams[shortKey] = params[key];
		});
		linkParams["v"] = 1;
		console.log("linkParams", JSON.stringify(linkParams));
		const paramsBase64 = arrayBufferToBase64(stringToArrayBuffer(JSON.stringify(linkParams)));
		const url = new URL(`/xlnk/${escapeBase64(paramsBase64)}`, window.location.origin).toString();
		return url;
	},
	cleanupTrackingParams () {
		trackingParamsKeys.forEach(key => {
			cookies.remove(key);
		});
	},
	storeTrackingParams () {
		const url = new URL(window.location.href);
		const params = {};
		[...url.searchParams.entries()].forEach(([key, value]) => {
			if (trackingParamsKeys.includes(key)) {
				params[key] = value;
			}
		});
		if (Object.keys(params).length) {
			common.cleanupTrackingParams();
			Object.keys(params).forEach(key => {
				cookies.set(key, params[key], {path: "/", expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30)}); // 30 days
			});
		}
	},
	getTrackingParams () {
		const url = new URL(window.location.href);
		const params = {};
		[...url.searchParams.entries()]
			.forEach(([key, value]) => {
				if (trackingParamsKeys.includes(key)) {
					params[key] = value;
				}
			});

		if (Object.keys(params).length) {
			return params;
		}

		trackingParamsKeys
			.forEach(key => {
				const value = cookies.get(key);
				if (value) {
					params[key] = value;
				}
			});
		return params;
	},
	async sendMobapplink (settings = {}) {
		const {phone} = settings;
		const utmParams = {};
		const additionalFromUrlParams = {};
		const url = new URL(window.location.toString());
		const captcha = captchaX({key: settings.recaptchaKey});
		[...url.searchParams.entries()].forEach(([key, value]) => {
			if (key.startsWith("utm_")) {
				utmParams[key] = value;
			}
			else if (key === "gclid" || key === "fbclid") {
				additionalFromUrlParams[key] = value;
			}
		});
		const device = (isMobile.phone ? "MOBILE" : (isMobile.tablet ? "TABLET" : "DESKTOP"));
		const $xcaptcha = await captcha;
		try { // gtag WWW-722
			(window.dataLayer || []).push({"event": "submit"});
		}
		catch {}
		const data = await fetch(`api/mobapplink/snd${common.urlParams(Object.assign({
			device: settings.redirMobileOnMarketAfterMesSend ? device : null,
		}, settings.params || {}, utmParams, additionalFromUrlParams))}`, {
			method: "POST",
			body: common.urlParams({
				phone,
				sendsrc: settings.sendsrc,
				...(settings.data || {}),
			}).replace(/^\?/, ""),
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/x-www-form-urlencoded",
				"x-captcha": $xcaptcha,
			},
		})
			.then(common.handleResponse);

		// if (settings.redirMobileOnMarketAfterMesSend && isMobile.phone) {
		// 	console.log("REDIRECT TO MARKET");
		// 	// this.trackConversion();
		// 	window.location = `get?device=mobile`;
		// 	return;
		// }

		return data;
	},
	bindData (...args) {
		const [src, prop, value] = args;
		let data = common.$data.get(src);
		if (!data) {
			if (args.length < 3) {
				return;
			}
			data = {};
			common.$data.set(src, data);
		}
		if (args.length === 2) {
			return data[prop];
		}
		data[prop] = value;
	},
	tryEx,
	bindBlurred () {
		[...$(`[data-blurred]`)].forEach(el => {
			const $el = $(el);
			const settings = (new Function(`return JSON.parse(JSON.stringify(${$el.attr("data-blurred") || "{}"}))`))();
			const {canvas, update} = blurred(settings);
			canvas.classList.add("blurred-canvas");
			$el.append(canvas);
			$el.css({"left": `-${settings.blur * 2}px`, "top": `-${settings.blur * 2}px`, "right": `-${settings.blur * 2}px`, "bottom": `-${settings.blur * 2}px`});
			update();
			setInterval(update, 500);
		});
	},
	isEmpty (val) {
		return tryEx(() => val == null || Object.keys(val).length === 0, true);
	},
	get (src, path) {
		if (src == null) {
			return undefined;
		}
		const p = path.replace(/["']/g, "").replace(/\[/g, ".").replace(/\]/g, "").split(".");
		let c = src;
		if (p[0]) {
			for (let i = 0; i < p.length; i++) {
				if (i === p.length - 1) {
					return c[p[i]];
				}
				c = c[p[i]];
				if (c == null || typeof c !== "object") {
					return undefined;
				}
			}
		}
		return c;
	},
	set (src, path, value) {
		const p = path.replace(/["']/g, "").replace(/\[/g, ".").replace(/\]/g, "").split(".");
		let c = src;
		if (p[0]) {
			for (let i = 0; i < p.length; i++) {
				if (i !== p.length - 1 && typeof c[p[i]] !== "object") {
					c[p[i]] = {};
				}
				if (i === p.length - 1) {
					c[p[i]] = value;
				}
				else {
					c = c[p[i]];
				}
			}
		}
		return src;
	},
	serverTime: tryEx(() => new Date(window.appSettings.datetime)),
	getTime () {
		return new Date(+common.serverTime + performance.now());
	},
	formatNumber (val, repl) {
		return (repl == null && (val).toLocaleString) ? (val).toLocaleString(app.settings.locale) : (val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, repl || " ");
	},
	formatQuant (val, html = true, minI = 0) {
		const orig = val;
		let i = 0;
		while (val >= 1000) {
			if (val < 10000) {
				val = Math.floor(val / 100) / 10;
			}
			else {
				val = Math.floor(val / 1000);
			}
			i++;
		}
		if (Math.log10(orig) < minI) {
			return `${common.formatNumber(orig)}`;
		}

		if (!html) {
			return `${common.formatNumber(val)} ${quant[app.settings.locale][i]}`;
		}
		return `${common.formatNumber(val)} <span class="format-quant">${quant[app.settings.locale][i]}</span>`;
	},
	randomInt (min, max) {
		let rand = min + Math.random() * (max + 1 - min);
		rand = Math.floor(rand);
		return rand;
	},
	cycle: (value, max = 1, min = 0) => {
		value = value - min;
		return (value < 0 ? ((max + (value % max)) % max) : value % max) + min;
	},
	line,
	lineLimit,
	plural (n) {
		n = Math.floor(n);
		return n % 10 == 1 && n % 100 != 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2;
	},
	escapeHtml (string) {
		const entityMap = {
			"&": "&amp;",
			"<": "&lt;",
			">": "&gt;",
			"\"": "&quot;",
			"'": "&#39;",
			"/": "&#x2F;",
			"`": "&#x60;",
			"=": "&#x3D;",
		};
		return String(string).replace(/[&<>"'`=/]/g, (s) => entityMap[s]);
	},
	initParticles (getSize) {
		const url = new URL(window.location.href);
		if (localStorage.particles === "false" || url.searchParams.has("noparticles")) {
			localStorage.particles = "false";
			window.stopParticles = () => {};
			window.startParticles = () => {};
			return () => {};
		}
		const particles = new Particles("#particles", 9);
		const $particlesContainer = $(".particles-container");

		function startParticles () {
			if (common.isMobile) {
				particles.pause();
			}
			else {
				particles.start();
			}
		}
		window.stopParticles = () => particles.pause();
		window.startParticles = startParticles;
		function updateParticlesContainerSize () {
			const size = (getSize && getSize()) || {};
			if (size.height) {
				$particlesContainer.css({"height": `${size.height}px`});
			}
			startParticles();
			particles.updateSize(size.width, size.height);
		}
		$(window).on("resize", () => updateParticlesContainerSize());
		updateParticlesContainerSize();
		return updateParticlesContainerSize;
	},
	animateCard (time = 300) {
		let lastScrollTop = window.pageYOffset;
		let animateCardTimeout;
		let animateCardState = 0;
		let animateCardResetTimeout;
		function animateCard () {
			if (!common.ignoreAll) {
				const scrollTop = window.pageYOffset;
				const delta = lastScrollTop - scrollTop;
				lastScrollTop = scrollTop;
				if (delta > 0) {
					if (animateCardState !== 1) {
						animateCardTimeout && clearTimeout(animateCardTimeout);
						animateCardTimeout = setTimeout(() => {
							$(".card-beautifier").removeClass("down").addClass("up");
						}, 0);
						animateCardState = 1;
					}
				}
				else if (delta < 0) {
					if (animateCardState !== -1) {
						animateCardTimeout && clearTimeout(animateCardTimeout);
						animateCardTimeout = setTimeout(() => {
							$(".card-beautifier").removeClass("up").addClass("down");
						}, 0);
						animateCardState = -1;
					}
				}
				if (delta !== 0) {
					animateCardResetTimeout && clearTimeout(animateCardResetTimeout);
					animateCardResetTimeout = setTimeout(() => {
						animateCardState = 0;
						$(".card-beautifier").removeClass("down up");
					}, time);
				}
			}
			requestAnimationFrame(animateCard);
		}
		animateCard();
	},
	timeout (callback, delay) {
		let cancel;
		const promise = Promise((resolve, reject) => {
			cancel = () => reject("cancel");
			setTimeout(() => resolve(callback()), delay);
		});
		promise.cancel = cancel;
		return promise;
	},
	nls (key) {
		return $(`.nls [key="${key}"]`).html();
	},
	preinit () {
		console.log("common preinit...");

		const frontAjaxEnabled = document.querySelector(`meta[name="fraj"]`);
		if (!(frontAjaxEnabled && frontAjaxEnabled.content == "false")) {
			// initFetch().then(() => {
			// 	fetchInterceptors.register({
			// 		request (request) {
			// 			if (!request.credentials) {
			// 				request.credentials = "include";
			// 			}
			// 			return request;
			// 		},
			// 		async response (response) {
			// 			const _response = response.clone();
			// 			// можно работать с response
			// 			return _response;
			// 		},
			// 	});
			// });
		}


		$(window).on("resize", () => {
			common.updateViewportSize();
		});

		common.updateViewportSize();
	},
	postinit (page, ctrl) {
		console.log("common postinit...");
		return ctrl;
	},
	// =========================================================================
	initModal () {
		$(".modal-wrapper").on("click", (event) => {
			if (event.target === event.currentTarget) {
				common.hideModal();
			}
		});
	},
	showModalX ({content, onClose, afterClose, closeOnClickOutside = true, closeOnEsc = true, wrapperClass} = {}) {
		const mode2 = true; // isSafari

		console.log();
		modalId++;
		const id = modalId;
		const modal = $(typeof content === "string" ? content.trim() : content);
		const $wrapper = $(`<div class="modal-wrapper"><div class="modal-overflow-wrapper"></div></div>`);
		if (wrapperClass) {
			$wrapper.addClass(wrapperClass);
		}
		if (closeOnClickOutside) {
			$wrapper.on("click", (event) => {
				if (event.target === event.currentTarget) {
					common.hideModal();
				}
			});
		}
		modal.appendTo($wrapper.find(".modal-overflow-wrapper"));
		$wrapper.prependTo(".modal-stack");
		if (onClose) {
			common.bindData(modal[0], "onClose", onClose);
		}

		// const url = new URL(window.location.href);
		// !url.searchParams.has("modal") && url.searchParams.append("modal", "show");
		history.pushState({id}, document.title, window.location.href);
		const fnKey = event => {
			if (closeOnEsc) {
				const key = event.which || event.keyCode;
				if (key === 27) {
					console.log("MODAL ESC");
					event.preventDefault();
					event.stopPropagation();
					common.hideModal();
				}
			}
		};

		const fn = event => {
			if (common.ignorePopstate) {
				return;
			}

			const eid = (event.state || {}).id;
			const isFirstChild = $wrapper.is(":first-child");
			if (isFirstChild) { //  && eid !== id
				window.removeEventListener("popstate", fn);
				common.ignorePopstate = true;
				history.pushState(null, document.title, window.location.href);
				history.go(-1);
				common.ignorePopstate = false;
				document.removeEventListener("keyup", fnKey);
				$wrapper.removeClass("open");
				modal.addClass("closing");
				if (onClose && !common.bindData(modal[0], "onCloseCalled")) {
					onClose(true);
				}

				setTimeout(() => {
					$wrapper.remove();
					const modals = $(".modal-wrapper .modal");
					if (!modals.length) {
						$("html, body").removeClass("modal");
						const mainContainer = document.querySelector("body > .main-container");
						let sp = 0;
						if (mode2) {
							if (mainContainer) {
								sp = mainContainer.scrollTop;
								mainContainer.style.overflow = "";
							}
							$("html, body").css({
								overflow: "",
								position: "",
								width: "",
								height: "",
							});
							document.scrollingElement.scrollTop = sp;
						}
					}
					afterClose && afterClose();
				}, 300);
			}
		};

		setTimeout(() => {
			modal.find("[autofocus]")[0]?.focus();
			let sp = 0;
			if (mode2) {
				sp = document.scrollingElement.scrollTop;
				[document.body, document.documentElement].forEach(el => {
					Object.assign(el.style, {
						position: "fixed",
						width: "100vw",
						height: "100vh",
						minHeight: "0px",
					});
					// el.style.cssText += "overflow: h !important;";
				});
			}

			$("html, body").addClass("modal");
			$wrapper.addClass("open");

			if (mode2) {
				const mainContainer = document.querySelector("body > .main-container");
				if (mainContainer) {
					mainContainer.scrollTop = sp;
					mainContainer.style.overflowY = "auto";
				}
			}

			window.addEventListener("popstate", fn);
			document.addEventListener("keyup", fnKey);
		}, 20);
		return modal;
	},
	showModal (content, onClose, afterClose) {
		return this.showModalX({content, onClose, afterClose});
	},
	hideModal (result) {
		const modals = $(".modal-wrapper .modal");
		if (modals.length) {
			const modal = modals.eq(0);
			const onClose = common.bindData(modal[0], "onClose");
			console.log("onClose", !onClose || (onClose && onClose(result) !== false));
			if (!onClose || (onClose && onClose(result) !== false)) {
				if (onClose) {
					common.bindData(modal[0], "onCloseCalled", true);
				}
				window.history.back();
			}
		}
	},
	initImgLazyLoad () {
		const images = window.document.querySelectorAll("source[data-srcset], img[data-src] , img[data-srcset]");

		const config = {
			rootMargin: "100px 0px",
			threshold: 0.01,
		};
		let observer;

		images.forEach(image => {
			const picture = image.closest("picture");
			const intersectTarget = (picture ? picture : image).dataset.intersect;
			observer = new IntersectionObserver((entries) => {
				entries.forEach(entry => {
					if (entry.intersectionRatio > 0) {
						// Stop watching and load the image
						observer.unobserve(entry.target);
						// call our method: preloadImage
						preloadImage(image);
					}
				});
			}, config);
			observer.observe(intersectTarget ? document.querySelector(intersectTarget) : image);
		});
		const preloadImage = (element) => {
			if (element.dataset && element.dataset.src) {
				element.src = element.dataset.src;
			}
			if (element.dataset && element.dataset.srcset) {
				element.srcset = element.dataset.srcset;
			}
		};
	},
	initScrollView (isDelay) {
		const odt = 0;
		const attrIn = "in-view";
		const attrNotIn = "not-in-view";
		const attrHiddenNotIn = "hidden-not-in-view";

		const intersectionObserver = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				// console.log("OBSERVER entry", entry);
				const el = entry.target;
				const isIntersecting = entry.isIntersecting;
				if (el.hasAttribute(attrIn)) {
					const classNames = el.getAttribute(attrIn).split(" ");
					let target = el.getAttribute("target-view");
					if (!target) {
						target = $(el);
					}
					else {
						target = target.trim();
						if (target.startsWith(">")) {
							target = ":scope " + target;
						}
						target = target.startsWith(":scope") ? $(el).find(target) : $(target);
						target = target[0] ? target : $(el);
					}
					classNames.forEach(className => {
						if (isIntersecting) {
							if (className.startsWith("-")) {
								target.removeClass(className.substring(1));
								if (isDelay) {
									setTimeout(() => {
										target.addClass("main-anim-complete");
									}, 230);

								}
							}
							else {
								target.addClass(className);
							}
							const callbackStr = el.getAttribute(`on-${attrIn}`);
							callbackStr && (new Function(callbackStr))();
						}
					});
				}
				if (el.hasAttribute(attrNotIn)) {
					const classNames = el.getAttribute(attrNotIn).split(" ");
					let target = el.getAttribute("target-view");
					target = $(target);
					target = target[0] ? target : $(el);
					classNames.forEach(className => {
						if (!isIntersecting) {
							if (className.startsWith("-")) {
								target.removeClass(className.substring(1));
							}
							else {
								target.addClass(className);
							}
							const callbackStr = el.getAttribute(`on-${attrNotIn}`);
							callbackStr && (new Function(callbackStr))();
						}
					});
				}
				if (el.hasAttribute(attrHiddenNotIn)) {
					const classNames = el.getAttribute(attrHiddenNotIn).split(" ");
					let target = el.getAttribute("target-view");
					target = $(target);
					target = target[0] ? target : $(el);
					classNames.forEach(className => {
						if (!isIntersecting && entry.boundingClientRect.width === 0 && entry.boundingClientRect.height === 0) {
							if (className.startsWith("-")) {
								target.removeClass(className.substring(1));
							}
							else {
								target.addClass(className);
							}
							const callbackStr = el.getAttribute(`on-${attrHiddenNotIn}`);
							callbackStr && (new Function(callbackStr))();
						}
					});
				}
			});

		});

		[...$(`[${attrIn}], [${attrNotIn}], [${attrHiddenNotIn}]`)].forEach(el => {
			intersectionObserver.observe(el);
		});

		let viewport = common.getViewport();

		const parallaxPositionType = {
			"top": 0,
			"center": 50,
			"middle": 50,
			"bottom": 100,
		};
		const $parallax = [...$(`[parallax]`)].map(el => {
			const $el = $(el);
			const move = +(el.getAttribute("parallax-move") || 0);
			const speed = +(el.getAttribute("parallax-speed") || 1);
			const position = el.getAttribute("parallax-position");
			const offset = +(el.getAttribute("parallax-offset") || 0);
			const direction = (el.getAttribute("parallax-direction") || "y");
			const tag = $(el).prop("tagName").toLowerCase();
			const _position = $(el).css("position");
			$el.css("position", "relative");
			const display = $el.css("display");
			console.log("_position", _position, $el.attr("style"));
			$el.css("position", ($el.attr("style") || "").includes("position") ? _position : "");

			return {
				el,
				speed,
				move,
				direction,
				position: +(parallaxPositionType[position] || position || 0) / 100,
				offset,
				shift: 0,
				wrapper: $(el).wrap(`<${tag} style="display: ${display}; min-width: 1px; min-height: 1px;" class="parallax-wrapper"></${tag}>`).parent()[0],
			};
		});

		function updateGeometry () {
			viewport = common.getViewport();
		}

		setInterval(updateGeometry, 2000);
		$(window).on("resize", updateGeometry);
		function updateParallax () {

			if (!common.isMobile) {
				$parallax.forEach(p => {
					const rect = common.cloneRect(p.wrapper.getBoundingClientRect());
					// console.log("rect", rect);
					if (!rect.width && !rect.height) {
						return;
					}
					const position = p.position * viewport.bottom;
					let val = 0;
					if (rect.top - position < 0) {
						val = -(rect.top - position) * Math.sign(p.move);
					}
					const shift = Math.round(common.lineLimit(val, 0, 0, p.move / p.speed, p.move) + p.offset);
					if (shift != p.shift) {
						p.active = true;
						p.shift = shift;
						$(p.el).css("transform", `translate${p.direction.toUpperCase()}(${shift}px)`);
					}
				});
			}
			else {
				$parallax.forEach(p => {
					if (p.active) {
						p.active = false;
						$(p.el).css("transform", "");
					}
				});
			}
		}

		updateGeometry();
		updateParallax();
		window.addEventListener("scroll", () => requestAnimationFrame(updateParallax), {passive: true});
	},
	initScrollSpy () {
		const placeholderText = $(".section-dropdown").eq(0).text();
		let activeMenuItem;
		let activeText;
		function showMenu () {
			$(".conditions__menu").addClass("show");
			$("html, body").addClass("show-menu");
			console.log("placeholderText", placeholderText);
			$(".section-dropdown").text(placeholderText);
			if (activeMenuItem && activeMenuItem[0]) {
				const scroller = $(".conditions-menu__list");
				scroller.scrollTop(scroller.scrollTop() + activeMenuItem.position().top - 70);
				// activeMenuItem.get(0).scrollIntoView();
			}
			// console.log("activeMenuItem", activeMenuItem, activeMenuItem.offset().top);
		}
		function hideMenu () {
			$(".conditions__menu").removeClass("show");
			$("html, body").removeClass("show-menu");
			$(".section-dropdown").text(activeText);
		}

		$(document).on("scrollspy", (event, {active}) => {
			activeText = active ? active.text() : placeholderText;
			activeMenuItem = active;
			if (activeMenuItem && activeMenuItem[0]) {
				const scroller = $(".conditions-menu__list");
				scroller.scrollTop(scroller.scrollTop() + activeMenuItem.position().top - 70);
				// activeMenuItem.get(0).scrollIntoView();
			}

			hideMenu();
		});
		$(".section-dropdown").on("click", event => {
			if ($("body").is(".show-menu")) {
				hideMenu();
			}
			else {
				showMenu();
			}
		});
	},
	cloneRect (rect) {
		return {
			top: rect.top,
			bottom: rect.bottom,
			left: rect.left,
			right: rect.right,
			height: rect.height,
			width: rect.width,
		};
	},
	getViewport () {
		return {
			left: 0,
			right: window.innerWidth || document.documentElement.clientWidth,
			top: 0,
			bottom: window.innerHeight || document.documentElement.clientHeight,
		};
	},
	inTopViewport (el) {
		if (el instanceof $) {
			el = el[0];
		}
		const rect = el.getBoundingClientRect();
		const viewport = common.getViewport();
		return rect.top < viewport.top && rect.bottom > viewport.top && !(rect.right < viewport.left || rect.left > viewport.right);
	},
	intersect (rect1, rect2) {
		return !(
			rect1.right < rect2.left ||
			rect1.left > rect2.right ||
			rect1.bottom < rect2.top ||
			rect1.top > rect2.bottom
		);
	},
	inViewport (el) {
		if (el instanceof $) {
			el = el[0];
		}
		const rect = el.getBoundingClientRect();
		const viewport = common.getViewport();
		return common.intersect(rect, viewport);
	},
	updateViewportSize () {
		const width = $(window).width();
		common.isDesktop = width >= 768;
		common.isMobile = width <= 620;
		common.isPhablet = width < 768;
		common.isTablet = width <= 1024 && width > 480;
	},
	toFormData (obj) {
		const result = new FormData();
		Object.keys(obj).forEach(key => result.append(key, obj[key]));
		return result;
	},
	handleResponse (response) {
		if (!response) {
			return;
		}
		const contentType = response.headers.get("content-type");
		if (contentType && contentType.includes("application/json")) {
			return common.handleJSON(response);
		}
		else if (response.status === 200) {
			return {ok: true};
		}
		return common.handleText(response);
	},
	async handleJSON (response) {
		if (!response.ok) {
			const data = await response.json();
			throw data;
		}
		return response.json();
	},
	async handleText (response) {
		if (!response.ok) {
			const data = await response.text();
			throw data;
		}
		return response.text();
	},
	urlParams (source) {
		return Object.keys(source || {}).filter(key => source[key] != null).map((key, idx) => `${idx === 0 ? "?" : ""}${key}=${encodeURIComponent(source[key])}`).join("&");
	},
	uniqId: (uniqLength = 16) => Array(uniqLength).fill(0).map(() => Math.random().toString(36).charAt(2)).map(ch => Math.random() > 0.5 ? ch.toUpperCase() : ch.toLowerCase()).join(""),
	objectToFormData (data) {
		if (data instanceof HTMLFormElement) {
			return new FormData(data);
		}
		if (!Array.isArray(data)) {
			data = Object.entries(data);
		}
		const fd = new FormData();
		data.forEach(([key, value]) => {
			if (value != null) {
				fd.append(key, value);
			}
		});
	},
	dataToUrlParams (data, filter = true) {
		if (data instanceof HTMLFormElement) {
			data = new FormData(data);
		}
		if (data instanceof FormData) {
			data = data.entries();
		}
		else {
			data = Object.entries(data);
		}
		data = data.filter(([key, value]) => value != null);
		return (new URLSearchParams(data)).toString();
	},
	validateEmail (email) {
		const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
		return re.test(email);
	},
	validateEdrpou (edrpou) {
		const re = /^(\d{8}|\d{10})$/;
		return re.test(edrpou);
	},
	validateForm (form) {
		let first;
		function removeError ($el) {
			const $formGroup = $el.closest("label");
			$el.removeClass("error");
			$formGroup.find(".hint.error").remove();
		}
		function setError ($el, message) {
			if (!first) {
				first = $el;
			}
			const $formGroup = $el.closest("label");
			$el.addClass("error");
			$el.one("input", () => removeError($el));
			$formGroup.append(`<span class="hint error">${message}</span>`);
		}
		[...$(form).find("[validate], [required]")].forEach(el => {
			const $el = $(el);
			const val = ($el.val() || "").trim();
			const type = $el.attr("validate");
			removeError($el);
			console.log("validate el", el, type, val);
			if (el.hasAttribute("required") && !val) {
				setError($el, common.nls("form.wrongrequired"));
				return;
			}

			switch (type) {
				case "phone-new": {
					const pval = ($el.valPhone() || "");
					if (!val) {
						setError($el, common.nls("form.wrongphone"));
					}
					else if ((pval.startsWith("+380") ? pval.length < 13 : pval.length < 9)) {
						setError($el, common.nls("form.wrongphone"));
					}
					break;
				}
				case "phone": {
					const pval = $el.inputParse() || "";
					console.log("pval", pval);
					if (!val) {
						setError($el, common.nls("form.wrongphone"));
					}
					else if ((pval.startsWith("+380") ? pval.length < 13 : pval.length < 9)) {
						setError($el, common.nls("form.wrongphone"));
					}
					break;
				}
				case "email": {
					if (!common.validateEmail(val)) {
						setError($el, common.nls("form.wrongemail"));
					}


					break;
				}
				case "edrpou": {
					if (!common.validateEdrpou(val)) {
						setError($el, common.nls("form.wrongedrpou"));
					}
					break;
				}
			}
		});
		if (first) {
			first?.[0]?.focus?.();
			return false;
		}
		return true;
	},
	resetFormErrors (form) {
		const $form = $(form);
		const $formError = $form.find(".form-error");
		return new Promise(resolve => {
			$formError.addClass("hidden");
			resolve();
		});
	},
	handleDrag (el, {start, move, end}) {
		el = $(el)[0];
		let last;
		let point;
		let pointerId;
		const sup = false;// typeof PointerEvent !== "undefined";
		const onstart = event => {
			if (pointerId) {
				return;
			}
			if (![1, 2].includes(event.button) || !sup) {
				pointerId = event.pointerId;
				let p = event;
				if (p.pageX == null && event.touches) {
					p = event.touches[0];
				}
				sup && el.setPointerCapture(event.pointerId);


				last = {x: p.pageX, y: p.pageY};
				const rect = el.getBoundingClientRect();
				point = {x: p.pageX - rect.x, y: p.pageY - rect.y};
				if (start && start(point, event.pointerType, event.pressure) !== false) {
					if (sup) {
						el.addEventListener("pointermove", onmove, {passive: false});
						el.addEventListener("pointerup", onend, {passive: false});
					}
					else {
						document.addEventListener("mousemove", onmove, {passive: false});
						document.addEventListener("mouseup", onend, {passive: false});
						document.addEventListener("touchmove", onmove, {passive: false});
						document.addEventListener("touchend", onend, {passive: false});
					}
				}
			}
		};
		const onmove = event => {
			if (event.pointerId === pointerId) {
				if (move) {
					let p = event;
					if (p.pageX == null && event.touches) {
						p = event.touches[0];
					}
					const point = {x: p.pageX, y: p.pageY};
					const delta = {x: point.x - last.x, y: point.y - last.y};
					last = point;
					move(delta, event.pointerType, event.pressure, event);
				}
			}
		};
		const onend = event => {
			if (sup) {
				if (event.pointerId === pointerId) {
					el.releasePointerCapture(event.pointerId);
					pointerId = null;
					el.removeEventListener("pointermove", onmove, {passive: false});
					el.removeEventListener("pointerup", onend, {passive: false});
					end && end();
				}
			}
			else {
				document.removeEventListener("mousemove", onmove, {passive: false});
				document.removeEventListener("mouseup", onend, {passive: false});
				document.removeEventListener("touchmove", onmove, {passive: false});
				document.removeEventListener("touchend", onend, {passive: false});
				end && end();
			}
		};


		if (sup) {
			el.addEventListener("pointercancel", onend, {passive: false});
			el.addEventListener("pointerdown", onstart, {passive: false});
			return function unbind () {
				el.removeEventListener("pointercancel", onend, {passive: false});
				el.removeEventListener("pointerdown", onstart, {passive: false});
				el.removeEventListener("pointermove", onmove, {passive: false});
				el.removeEventListener("pointerup", onend, {passive: false});
			};
		}
		else {
			el.addEventListener("touchstart", onstart, {passive: false});
			el.addEventListener("mousedown", onstart, {passive: false});
			return function unbind () {
				el.removeEventListener("touchstart", onstart, {passive: false});
				el.removeEventListener("mousedown", onstart, {passive: false});
				document.removeEventListener("touchmove", onmove, {passive: false});
				document.removeEventListener("mousemove", onmove, {passive: false});
				document.removeEventListener("touchend", onend, {passive: false});
				document.removeEventListener("mouseup", onend, {passive: false});
			};
		}
	},
};
window.common = common;
export default common;

export class Deferred {
	constructor () {
		this.promise = new Promise((resolve, reject) => {
			this.ctrl = {resolve, reject};
		});
	}
	resolve (...args) {
		return this.ctrl.resolve(...args);
	}
	reject (...args) {
		return this.ctrl.reject(...args);
	}
}

$("#acquiring-title").on("click", event => {
	event.preventDefault();
	event.stopPropagation();
	$("#acquiring-links").toggle();
	setTimeout(() => {
		$("body").on("click", hideAcquiringLinks);
	}, 300);
});

function hideAcquiringLinks (event) {
	$("#acquiring-links").hide();
	$("body").off("click", hideAcquiringLinks);
}


$("#api-title").on("click", event => {
	event.preventDefault();
	event.stopPropagation();
	$("#api-links").toggle();
	setTimeout(() => {
		$("body").on("click", hideApiLinks);
	}, 300);
});

function hideApiLinks (event) {
	$("#api-links").hide();
	$("body").off("click", hideApiLinks);
}
